<template>
  <inner-card class="card" :colour-class="isSuccess ? 'clarity-gradient-light-green' : isWarn ? 'clarity-gradient-pale-orange' : isError ? 'clarity-gradient-red' : ''">
    <template #title>
      <div class="flex justify-content-between">
        <div class="flex">
          <span class="material-icons-round mr-3" :class="[isSuccess ? 'green' : isWarn ? 'orange' : isError ? 'red' : '']">{{icon}}</span>
          <h3 class="title" :class="[isSuccess ? 'green' : isWarn ? 'orange' : isError ? 'red' : '']">{{ title }}</h3></div>
        <Button class="clarity-close-modal-btn" @click="$emit('close')">
          <span class="material-icons-round grey">close</span>
        </Button>
      </div>
    </template>
    <template #content>
      <div class="ml-6" v-if="content">{{ content }}</div>
      <div class="ml-6" v-if="html" v-html="html"></div>
    </template>
  </inner-card>
</template>

<script>
import InnerCard from "@/components/common/InnerCard";
import {computed} from "vue";

export default {
  name: "Notification",
  components: {
    InnerCard
  },
  props: ['type', 'icon', 'title', 'content', 'html'],
  setup(props) {
    const isSuccess = computed(() => props.type === 'success')
    const isWarn = computed(() => props.type === 'warn')
    const isError = computed(() => props.type === 'error')

    return {
      isSuccess,
      isWarn,
      isError
    }
  }
}
</script>

<style scoped>

</style>