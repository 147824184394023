<template>
  <span>{{ formattedValue ?? '' }}</span>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "CurrencyValue",
  props: {
    value: Number,
    decimalPlaces: {
      type: Number,
      default: 2
    },
    showSymbol: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore()

    const selectedOption = computed(() => store.getters.currencySelected );
    const currencyOptions = computed(() => store.state.selections.currencyOptions);

    const formattedValue = computed(() => {
      let value = props.value;
      if(selectedOption.value != 'GBP'){
        // if display is not in GBP we need to convert the currency.
        const conversionCurrency = currencyOptions.value.find(obj => {
          return obj.currencyCode === selectedOption.value
        });
        value = value*conversionCurrency.conversionrate;
      }
      if(props.showSymbol) {
        return new Intl.NumberFormat('en-GB', { minimumFractionDigits: props.decimalPlaces, maximumFractionDigits: props.decimalPlaces, style: 'currency', currency: selectedOption.value}).format(value)
      }else{
        return new Intl.NumberFormat('en-GB', { minimumFractionDigits: props.decimalPlaces, maximumFractionDigits: props.decimalPlaces }).format(value)
      }
    })

    return {
      selectedOption,
      formattedValue
    }
  }
}
</script>

<style scoped>

</style>
