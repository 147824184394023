<template>
  <div class="content-full">
    <div  v-if="investingWith !== 'Aegon'">
      <Notification id="invest-now-notice" type="success" icon="check_circle" title="Success: Everything was done correctly!"
                    content="You are now being redirected to the Provider's website. Purchases must be made using a
                      Debit Card held in the name of the Investor."/>

      <div class="flex justify-content-center p-6">
        <Loading />
      </div>
    </div>
    <AddAegon v-if="investingWith === 'Aegon'" />
    <div v-if="investingWith === 'Aegon'" class="flex justify-content-between" >
      <Button class="clarity-btn clarity-gradient-grey" @click="$router.push('/investments/add/terms')"><span class="material-icons-round grey">chevron_left</span>Go Back</Button>
    </div>
  </div>
</template>

<script>
import Notification from "@/components/common/Notification";
import AddAegon from "@/components/addInvestment/AddAegon";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "StepComplete",
  components: {Notification, AddAegon},
  setup() {
      const store = useStore();

      const investingWith = computed(() => store.getters.getInvestWithProviderName);

      const fnRedirect = async () => {
          await new Promise(r => setTimeout(r, 4000));
          window.location.replace("https://www.fidelity.co.uk/accounts/login")
      }

      if(investingWith.value === 'Fidelity Adviser Solutions'){
          fnRedirect();
      }

      return {
          investingWith
      }
  }
}
</script>

<style scoped lang="scss">
.content-full {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
</style>
