<template>
    <div v-if="!isAegon && !isAegonISA" style="margin-bottom: 10px;">
        <h3 class="dark-grey clarity-margin-bottom-xs">STEP 3: CONFIRMATION</h3>
        <div class="grid grid-nogutter clarity-margin-top-m">
            <div class="col-fixed aegonInvestImg">
                <img src="@/assets/img/Invest-on-ISA-Image.png" alt="Aegon ISA">
            </div>
            <div id="aegonISA" class="col clarity-margin-left-l">
                <h3 class="dark-grey">STOCKS AND SHARES INDIVIDUAL SAVINGS ACCOUNT (ISA)</h3>
                <h5 class="blue">What is a stocks and shares ISA?</h5>
                <p class="body">A stocks and shares ISA is a tax-efficient way to save for the medium to long term. Through us you have access to a range of different investment opportunities and can invest a regular monthly amount, a lump sum - or both.</p>
                <h5 class="blue">Investing in a stocks and shares ISA</h5>
                <ul>
                    <li>You can invest your full ISA annual allowance of £20,000, for the current tax year, in a stocks and shares ISA.</li>
                    <li>Invest a lump sum, make regular monthly investments or do both.</li>
                    <li>Choose from a range of different investment opportunities.</li>
                    <li>You can transfer in any existing ISAs you have.</li>
                    <li>You have no personal liability to income tax or capital gains tax on any money you take from a stocks and shares ISA.</li>
                </ul>
                <Button class="clarity-btn clarity-gradient-orange clarity-margin-top-s" @click="applyAegon('isa')" >Apply now with Aegon</Button>
            </div>
        </div>
        <div id="aegonGIA" class="grid grid-nogutter clarity-margin-top-l">
            <div class="col-fixed aegonInvestImg">
                <img src="@/assets/img/Invest-on-GIA-Image.png" alt="Aegon GIA">
            </div>
            <div class="col clarity-margin-left-l">
                <h3 class="dark-grey">GENERAL INVESTMENT ACCOUNT (GIA)</h3>
                <h5 class="blue">What is a General Investment Account (GIA)? </h5>
                <p class="body">A GIA is medium to long term investment that could be a good option if you've used up your ISA annual allowance and don't want to lock your money away in a pension. There's no limit on how much you can invest.</p>
                <h5 class="blue">Investing in a GIA</h5>
                <ul>
                    <li>Invest without any annual or lifetime limits.</li>
                    <li>Invest a lump sum, make regular contributions, or do both.</li>
                    <li>You can open an account individually or in joint names.</li>
                    <li>Choose from a range of different investment opportunities.</li>
                    <li>You can transfer in any existing GIAs you may have.</li>
                </ul>
                <Button class="clarity-btn clarity-gradient-orange clarity-margin-top-s clarity-margin-bottom-s" @click="applyAegon('gia')">Apply now with Aegon</Button>
            </div>
        </div>
    </div>

    <div v-if="isAegon && isAegonISA" style="margin-bottom: 10px;">
        <h3 class="dark-grey clarity-margin-bottom-xs">STEP 3: CONFIRMATION</h3>
        <h3 class="dark-grey clarity-margin-top-ms clarity-margin-bottom-xs">YOUR AEGON INVESTMENTS</h3>
        <div class="grid">
            <div v-for="policy in policyData" :key="policy.id" class="col-12 xl:col-4">
                <inner-card>
                    <template #content>
                        <aegon-policy-card :policy="policy"></aegon-policy-card>
                    </template>
                </inner-card>
            </div>
        </div>
    </div>

    <div v-if="isAegon && !isAegonISA" style="margin-bottom: 10px;">
        <h3 class="dark-grey clarity-margin-bottom-xs">STEP 3: CONFIRMATION</h3>
        <div class="grid grid-nogutter clarity-margin-top-m">
            <div class="col-fixed aegonInvestImg">
                <img src="@/assets/img/Invest-on-ISA-Image.png" alt="Aegon ISA">
            </div>
            <div id="aegonISA" class="col clarity-margin-left-l">
                <h3 class="dark-grey">STOCKS AND SHARES INDIVIDUAL SAVINGS ACCOUNT (ISA)</h3>
                <h5 class="blue">What is a stocks and shares ISA?</h5>
                <p class="body">A stocks and shares ISA is a tax-efficient way to save for the medium to long term. Through us you have access to a range of different investment opportunities and can invest a regular monthly amount, a lump sum - or both.</p>
                <h5 class="blue">Investing in a stocks and shares ISA</h5>
                <ul>
                    <li>You can invest your full ISA annual allowance of £20,000, for the current tax year, in a stocks and shares ISA.</li>
                    <li>Invest a lump sum, make regular monthly investments or do both.</li>
                    <li>Choose from a range of different investment opportunities.</li>
                    <li>You can transfer in any existing ISAs you have.</li>
                    <li>You have no personal liability to income tax or capital gains tax on any money you take from a stocks and shares ISA.</li>
                </ul>
                <Button class="clarity-btn clarity-gradient-orange clarity-margin-top-s" @click="applyAegon('isa')">Apply now with Aegon</Button>
            </div>
        </div>
        <h3 class="dark-grey clarity-margin-top-ms clarity-margin-bottom-xs">YOUR AEGON INVESTMENTS</h3>
        <div class="grid">
            <div v-for="policy in policyData" :key="policy.name" class="col-12 xl:col-4">
                <inner-card>
                    <template #content>
                        <aegon-policy-card :policy="policy"></aegon-policy-card>
                    </template>
                </inner-card>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import InnerCard from '@/components/common/InnerCard';
import api from "@/core/services/api";
import {toDrupalRequest} from "@/core/helpers";
import AegonPolicyCard from '@/components/addInvestment/AegonPolicyCard';
import dayjs from "dayjs";

export default {
    name: "AddAegon",
    components: {InnerCard, AegonPolicyCard},
    setup() {
        const store = useStore();

        const isAegon = ref(false);
        const isAegonISA = ref(false);
        const policyData = ref([]);

        const policyOptions = computed(() => {
            const stateValue = store.state.selections.policyOptions;
            if(stateValue) {
                return stateValue;
            }else{
                return false;
            }
        });

        const applyAegon = async (action) => {
            if(action === 'isa') {
                window.open('https://claritybuy.aegon.co.uk/first-purchase/stocks-and-shares-isa/product-info/');
            }
            if(action === 'gia') {
                window.open('https://claritybuy.aegon.co.uk/first-purchase/gia/product-info/');
            }
        }

        const getPolicies = async () => {
            policyOptions.value.forEach(policy => {
                if(policy.planstring.includes('15::')) {
                    isAegon.value = true;
                    getPolicyData(policy.planstring,policy.name,policy.ownership,policy.clarityType);
                    if(policy.productType === 'isa') {
                        isAegonISA.value = true;
                    }
                }
            });
        }

        const getOwnerFromUuid = (owner) => {
            let rtn = [];
            owner.forEach(person => {
                const name = store.getters.personFromUuid(person);
                name ? rtn.push(name.name) : false;
            });
            return rtn.join(", ");
        }

        const getTotal = async (uuid) => {
            const endpoint = `/valuation/plan/summary/${uuid}`;
            const response = await api.get(`${toDrupalRequest(endpoint)}`);

            if (response.total) {
                return response.total;
            }
        }

        const getPolicyTypeLabel = (holdingText) => {
            switch (holdingText) {
                case 'clarity':
                    return ' investment'
                case 'selfmanagedholding':
                    return 'Self Managed Holding'
                case 'openbanking':
                    return 'Open Banking'
                default:
                    return ' investment'
            }
        }

        const getPolicyData = async (uuid,name,owner,holding) => {
            const id = uuid.slice(4);
            const PolicyValue = await getTotal(uuid);
            const presentDate = computed(() => {
                const date = store.state.valuation.valuationDate;
                return new dayjs(date)
            });
            const holdingText = getPolicyTypeLabel(holding);
            policyData.value.push({'id':id,'name':name,'value':PolicyValue,'valueDate':presentDate.value.format('DD-MM-YYYY'),'owner':getOwnerFromUuid(owner),'holding':holdingText});
        }

        onMounted(() => {
            getPolicies();
        });

        return {
            isAegon,
            isAegonISA,
            policyData,
            applyAegon
        }
    }
}
</script>

<style scoped lang="scss">
    .aegonInvestImg {
        width: 512px;
    }
    .aegonInvestImg img {
        width: 512px;
    }
    #aegonISA h5, #aegonGIA h5 {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    #aegonISA li, #aegonGIA li {
        margin-bottom: 14px;
    }
    .col-12 xl:col-4 {
        padding: 1.5rem !important;
    }
</style>
