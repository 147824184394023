<template>
    <div class="valuationCard">
        <h3 class="blue">{{ policy.name }}</h3>
        <p class="small-text grey">Owners: {{ policy.owner }}</p>
        <p v-if="policy.holding ===' investment'" class="small-text grey"><img class="clarityLogo" src="@/assets/logos/clarity-logo-small-blue.svg"/>{{ policy.holding }}</p>
        <p v-if="policy.holding !== ' investment'" class="small-text grey">{{ policy.holding }}</p>
        <h1 class="blue"><currency-value :value="policy.value" :decimal-places="0" /></h1>
        <p class="small-text grey">Policy Value as at {{ policy.valueDate }}</p>
        <div class="aeActionBtns">
            <Button class="clarity-btn clarity-gradient-grey"  v-tooltip.top="'Top up account'" style="width: 54px; padding-left: 14px; padding-right: 14px;" @click="policyAction('Top',policy.id)"><img src="@/assets/icons/add-money-icon.png"></Button>
            <Button class="clarity-btn clarity-gradient-grey"  v-tooltip.top="'Complete a switch'" style="width: 54px; padding-left: 14px; padding-right: 14px;" @click="policyAction('Switch',policy.id)"><span class="material-icons">sync_alt</span></Button>
            <Button class="clarity-btn clarity-gradient-grey"  v-tooltip.top="'Set up Regular Transaction'" style="width: 54px; padding-left: 14px; padding-right: 14px;" @click="policyAction('Regs',policy.id)"><span class="material-icons">autorenew</span></Button>
            <Button class="clarity-btn clarity-gradient-grey"  v-tooltip.top="'View your documents'" style="width: 54px; padding-left: 14px; padding-right: 14px;" @click="policyAction('Docs',policy.id)"><span class="material-icons">description</span></Button>
        </div>
        <div id="aegonForm" style="display:none;"></div>
    </div>
</template>

<script>
import { toRef } from "vue";
import CurrencyValue from "@/components/common/CurrencyValue";
import api from "@/core/services/api";
import {toDrupalRequest} from "@/core/helpers";

export default {
    name: 'AegonPolicyCard',
    components: {CurrencyValue},
    props: {
        policy: Object
    },
    setup(props) {
        const policyData = toRef(props, 'policy');

        const policyAction = async (action,policy) => {
            await aeRedirect(action,policy);
        }

        const aeRedirect = async (context,policy) => {
            await new Promise(r => setTimeout(r, 1000));
            const endpoint = '/idp/aegon/sso';
            const response = await api.post(
                `${toDrupalRequest(endpoint)}`,
                JSON.stringify({
                    "wrapper_id": policy,
                    "context": context
                }),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            if(!response) {
                alert('ERROR: error has occurred during redirect process.')
            }
            //Parse using DOMParser native way
            const parser = new DOMParser();
            const theDom = parser.parseFromString(response, 'text/html');
            const form = theDom.body.querySelector('body > form');
            document.getElementById("aegonForm").appendChild(form);
            form.submit();
        }

        return {
            policyData,
            policyAction
        }
    }
}
</script>

<style scoped lang="scss">
    .clarity-card-inner h1, .clarity-card-inner h3, .clarity-card-inner p {
        text-align: center;
    }
    .aeActionBtns {
        text-align: center;
    }
    .aeActionBtns button {
        margin-right: 20px;
    }
    .clarityLogo {
        vertical-align: middle;
    }
</style>
